import { Splide } from '@splidejs/splide';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';


gsap.registerPlugin(ScrollTrigger)

/*ANIMATION HEADER*/
const mainTitle = document.getElementById("mainTitle")
const body = document.querySelector("body")

window.addEventListener("load",()=>{
    gsap.to(mainTitle,{
        y:0,
        autoAlpha:1,
        duration:1.5,
        ease:"power2"
    })
})

/*ANIMATION SECTION 1*/

const section_1 = document.getElementById("section_1")
const section_1_text = document.getElementById("section_1-text")
const section_1_title = document.querySelector(".section_1 h5")
const section_1_timeline = gsap.timeline({
    defaults:{
        duration:1,
        ease:'power2',
    },
    scrollTrigger:{
        trigger:section_1,
        start:"top center"
    }
    
})

gsap.to(section_1_text,{
    "--height" : "150%",
    duration:1.5,
    ease:"power1",
    scrollTrigger:{
        trigger:section_1,
        start:"top center"
    }
})

gsap.to(section_1_title,{
    "--width" : "100%",
    duration:1.5,
    ease:"power1",
    scrollTrigger:{
        trigger:section_1,
        start:"top center"
    }
})

section_1_timeline
.to(section_1_title,{y:0,autoAlpha:1})
.to(document.querySelector("#section_1 h2"),{y:0,autoAlpha:1},'-=0.75')
.to(document.querySelector("#section_1 p"),{y:0,autoAlpha:1},'-=0.75')

/*ANIMATION SECTION 2*/
const section_2= document.getElementById("section_2")
const section_2_img = document.querySelector("#section_2 .section-img")
const section_2_title = document.querySelector("#section_2 h5")
const section_img_absolute_1 = document.querySelector(".section-img-absolute-1")
const section_img_absolute_2 = document.querySelector(".section-img-absolute-2")
const section_2_timeline = gsap.timeline({
    defaults:{
        duration:1,
        ease:'power2',
    },
    scrollTrigger:{
        trigger:section_2,
        start:window.innerWidth>660?"top center":"top+=10% bottom",
    },    
})

gsap.to(section_2,{
    "--width" : window.innerWidth>660?"70%":"92%",
    duration:1.5,
    ease:"power1",
    scrollTrigger:{
        trigger:section_2,
        start:"top center"
    }
})

gsap.to(section_2_img,{
    "--heightAfter" : window.innerWidth>660?"180%":"155%",
    y:0,
    autoAlpha:1,
    duration:1.5,
    ease:"power1",
    scrollTrigger:{
        trigger:section_2,
        start:"top center"
    }
})

gsap.to(section_img_absolute_1,{
    x:0,
    autoAlpha:1,
    ease:"power1",
    scrollTrigger:{
        trigger:section_2,
        start:window.innerWidth>660?"bottom-=250px bottom":"bottom+=100px bottom",
        end:window.innerWidth>660?"bottom-=200px center":"bottom center",
        scrub:2,
    }
})
gsap.to(section_img_absolute_2,{
    x:0,
    autoAlpha:1,
    ease:"power1",
    scrollTrigger:{
        trigger:section_2,
        start:window.innerWidth>660?"bottom+=50px bottom":"bottom+=300px bottom",
        end:window.innerWidth>660?"bottom-=100px center":"bottom+=200px center",
        scrub:2,
    }
})

section_2_timeline
.to(section_2_title,{y:0,autoAlpha:1})
.to(document.querySelector("#section_2 h2"),{y:0,autoAlpha:1},'-=0.75')
.to(document.querySelector("#section_2 p"),{y:0,autoAlpha:1},'-=0.75')

/*ANIMATION SECTION 3*/
// const section_3= document.querySelector(".section_3-title")
// const section_3_title = document.querySelector(".section_3-title h2")
// const section_3_subtitle = document.querySelector(".section_3-title h5")
// const section_3_timeline = gsap.timeline({
//     defaults:{
//         duration:1,
//         ease:'power2',
//     },
//     scrollTrigger:{
//         trigger:section_3,
//         start:window.innerWidth>660?"top center":"top+=10% bottom",
//     },    
// })
// section_3_timeline
// .to(section_3_title,{
//     "--width" : "100%",
//     "--height" : "200%",
//     y:0,
//     autoAlpha:1,
//     ease:"power1",
// })
// .to(section_3_subtitle,{y:0,autoAlpha:1},'-=0.75')

/*ANIMATION SECTION 4*/
const section_4= document.getElementById("section_4")
const section_4_title = document.querySelector(".section_4-title h2")
const section_4_subtitle = document.querySelector(".section_4-title h5")
const section_4_timeline = gsap.timeline({
    defaults:{
        duration:1,
        ease:'power2',
    },
    scrollTrigger:{
        trigger:section_4,
        start:window.innerWidth>660?"top center":"top+=10% bottom",
    },    
})
section_4_timeline
.to(section_4_title,{y:0,autoAlpha:1})
.to(section_4_subtitle,{y:0,autoAlpha:1},'-=0.75')

let carousel = document.querySelector('.splide__list');
let state = 0
let carouselWidth,articleWidth,gap;
const setWidth = ()=>{
    console.log(body.clientWidth)
    if(window.innerWidth>660){
        carouselWidth = body.clientWidth/100*168.75
        articleWidth = body.clientWidth*45.833/100
    }else{
        carouselWidth = body.clientWidth/100*280
        articleWidth = body.clientWidth*85/100
    }
    carousel.style.left = "50%"
    gap = (carouselWidth-articleWidth*3)/2
    state = 0
}
window.addEventListener("load",()=>{setWidth()})
window.addEventListener("resize",()=>{setWidth()})

document.getElementById('left').addEventListener('click', () => goLeft());
document.getElementById('right').addEventListener('click', () => goRight());

function goLeft() {
    let pos = window.getComputedStyle(carousel).getPropertyValue('left').replace('px', '');
    if(state<0){
        return
    }
    state--
    carousel.style.left = parseInt(pos) + (articleWidth+gap) + 'px';
}

function goRight() {
    if(state>0){
        return
    }
    state++
    let pos = window.getComputedStyle(carousel).getPropertyValue('left').replace('px', '');
    carousel.style.left = parseInt(pos) - (articleWidth+gap) + 'px';
}

new Splide( '.splide',{
    mediaQuery: 'min',
    width:1920,
    gap:"8%",
    perPage:1,
    arrows:false,
    pagination:false,
    breakpoints: {
        660: {
            destroy:true,
        }
      }
}).mount();

